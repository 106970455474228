import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../Common/SectionTitle';
import SingleProject from './SingleProject';

import imgAndonZurg from '../../assets/img/01-home/project-andon-zurg.png';
import imgEasyControl from '../../assets/img/01-home/project-easy-control.png';

const Project = () => {

    const options = {
        items: 3,
        nav: true,
        dots: true,
        margin: 270,
        rewind: false,
        autoplay: true,
        stagePadding: 100,
        loop: false,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                nav: false,
            },
            768: {
                items: 2,
                nav: false,
                stagePadding: 0,
            },
            992: {
                items: 2,
                nav: false,
                stagePadding: 0,
            },
            1200: {
                items: 2,
                nav: false,
            },
            1500: {
                items: 2,
                nav: false,
                stagePadding: 0,
            }
        }
    };

	return(
        <React.Fragment>
            <div id="rs-portfolio" className="rs-project style1 pt-100 mb-100 md-70">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-70 md-30"
                        subtitleClass="sub-text black-color"
                        subtitle="PRODUTOS"
                        titleClass="title black-color"
                        title="Confira algumas soluções ZURG"
                        effectClass="heading-line"
                    />
                    
                    <OwlCarousel options={options} >
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={imgAndonZurg} 
                            Title="Andon Zurg" 
                            Category="Saiba mais"
                            Reference="product-andon-zurg"
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={imgEasyControl} 
                            Title="Easy Control" 
                            Category="Saiba mais" 
                            Reference="product-easy-control"
                        />
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
	)
}

export default Project