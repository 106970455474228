import React from 'react';
import SectionTitle from '../Common/SectionTitle';

const ContactInfo = (props) => {
    const { contactBoxClass } = props;
    return (
        <div className={contactBoxClass ? contactBoxClass : 'contact-box'}>
            <SectionTitle
                sectionClass="sec-title mb-200"
                subtitleClass="sub-text new-text white-color"
                subtitle="VAMOS CONVERSAR?"
                titleClass="title white-color"
                title={<p style={{lineHeight:'120%'}}>Nós queremos <br/> ouvir suas <br/> ideias!</p>}
            />

            <div className="address-box">
                <div className="address-icon">
                    <i className="fa fa-envelope"></i>
                </div>
                <div className="address-text">
                    <span className="label">E-mail</span>
                    <a href="mailto:contato@zurg.com.br">contato@zurg.com.br</a>
                </div>
            </div>
        </div>
    );

}

export default ContactInfo;