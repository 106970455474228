import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/img/10-logo/logo-zurg.png';

const TopHeader = () => {

    return (
        <div className="topbar-area hidden-md">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2">
                        <div className="logo-part">
                            <Link to="/"><img src={Logo} alt="Logo"/></Link>
                        </div>
                    </div>
                    <div className="col-lg-10 text-right" style={{alignSelf:'center'}}>
                        <ul className="rs-contact-info" style={{marginTop:'3px', marginBottom:'-3px'}}>
                            <li className="contact-part">
                                <i className="flaticon-email"></i>
                                <span className="contact-info">
                                    <span>E-mail</span>
                                    <a href="mailto:contato@zurg.com.br"> contato@zurg.com.br</a>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopHeader;