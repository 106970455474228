import React  from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../../components/Common/SectionTitle';
import SingleProcess from '../../../components/Common/Process';

const Process = () => {

    return (
        <div className="rs-process gray-bg pt-100 pb-100 md-70">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-46"
                    subtitleClass="sub-text primary-color"
                    subtitle="METODOLOGIA"
                    titleClass="title"
                    title="Como começamos um novo desenvolvimento?"
                    effectClass="heading-line"
                />
                <div className="row align-items-center">
                    <div className="col-lg-4 md-mb-40">
                        <div className="process-wrap">
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text new"
                                subtitle={<p>Desenvolvimento</p>}
                                titleClass="title2 white-color"
                                title={
                                    <p style={{lineHeight:'120%'}}> Se trabalhamos <br/> bem, é porque <br/> nossos <br/> clientes <br/> nos ensinam <br/> a melhorar. </p>
                                }
                            />
                            <div className="btn-part mt-40">
                                <Link to="/service" className="readon learn-more">Saiba mais</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 pl-30 md-pl-15">
                        <div className="row">
                            <div className="col-md-6 mb-40">
                                <SingleProcess 
                                    processNumber="1"
                                    processTitle="Levantamento das necessidades"
                                    processText="Identificamos quais são as dores e expectativas de nossos clientes para assim, sabermos como resolvê-los."
                                />                                
                            </div>
                            <div className="col-md-6 mb-40">                                
                                <SingleProcess 
                                    processNumber="2"
                                    processTitle="Planejamento e documentação"
                                    processText="Todas as considerações são importantes para tornar os desenvolvimentos mais assertivos, alcançando os objetivos propostos na etapa anterior."
                                />
                            </div>
                            <div className="col-md-6 sm-mb-40">
                                <SingleProcess 
                                    processNumber="3"
                                    processTitle="Execução do projeto"
                                    processText="Após todas as pesquisas e planejamento realizados, é a hora de colocarmos tudo em prática e implementar o projeto de forma eficiente e eficaz."
                                />
                            </div>
                            <div className="col-md-6">
                                <SingleProcess 
                                    processNumber="4"
                                    processTitle="Avaliação dos resultados"
                                    processText="Esta última etapa é fundamental para manter toda a estratégia funcionando corretamente e trazer os resultados desejados para nossos amigos clientes e parceiros."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Process;