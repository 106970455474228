import React from 'react';

import SectionTitle from '../../../components/Common/SectionTitle';
import ScrollToTop from '../../../components/Common/ScrollTop';
import VideoStyleThree from '../../../components/Video/VideoStyleThree';

import contentImg1 from '../../../assets/img/04-project/easy-control/content-1.png';
import contentImg2 from '../../../assets/img/04-project/easy-control/content-2.png';

const EasyControlContent = () => {
    return (
        <React.Fragment>
            {/* About Section Start */}
            <div className="rs-process gray-bg pt-100 pb-100 md-70">
                {/* <div className="container"> */}
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-50"
                        subtitleClass="sub-text primary-color"
                        subtitle="PRODUTOS"
                        titleClass="title"
                        title="Easy Control"
                        effectClass="heading-line"
                    />

                    <div style={{textAlign:'center', marginBottom:'50px'}}>
                        <p>
                            O <b>Easy Control</b> chegou para revolucionar todos os conceitos em controle de acessos, seguindo como a materialização 
                            de um sonho <br/> de seus fundadores, profundos conhecedores de todas as dificuldades que existem desde a integração até o 
                            uso das soluções atuais. 
                        <br/>
                        <br/>
                            Segura, robusta, confiável e clean de ponta a ponta, da integração à operação nas portarias.
                        </p>
                    </div>

                    <div style={{marginBottom:'50px'}}>
                        <img src={contentImg1} alt=""/>
                    </div>

                    <div style={{textAlign:'center', marginBottom:'50px'}}>
                        <p>
                            Ele tem como principal característica o cadastro simplificado, onde o porteiro/recepcionista faz tudo de maneira fácil, rápida e sem complicações.
                        <br/>
                        <br/>
                            Uma vez feito o cadastro, o operador decide a quais locais o usuário poderá ter acesso, de forma ágil e intuitiva, de acordo com horário, <br/> dias da semana, faixa etária e outras informações apontadas pelo operador.
                        </p>
                    </div>

                    <div style={{marginBottom:'50px'}}>
                        <img src={contentImg2} alt=""/>
                    </div>

                    <div style={{textAlign:'center'}}>
                        <p>
                            Acesse o site <a href='http://www.easy5.com.br' target='_blank' style={{color:'#3366FF'}}>www.easy5.com.br</a> para maiores informações.
                        </p>
                    </div>
                {/* </div> */}
            </div>
            {/* About Section End */}
            
            {/* Contact area start */}
            <div class="rs-contact-wrap bg-gradient pt-100 pb-390 md-pt-80">
                <div class="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Contato"
                        titleClass="title title2 white-color"
                        title="Vamos conversar?"
                    />
                </div>
            </div>
            <VideoStyleThree />
            {/* Contact area end */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}

export default EasyControlContent;