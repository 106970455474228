import React from 'react';
import Header from '../../../components/Layout/Header';
import Footer from '../../../components/Layout/Footer';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import EasyControlContent from './EasyControlContent';

// Breadcrumbs Background Image
import bannerbg from '../../../assets/img/04-project/easy-control/banner.png';


const AndonZurg = () => {

    return (
        <React.Fragment>
            <Header
                parentMenu='product-easy-control'
                secondParentMenu='product'
                activeMenu='product-easy-control'
            />
            <div>
                {/* breadcrumb-area-start */}
                <SiteBreadcrumb
                    pageTitle="Easy Control"
                    titleClass="page-title"
                    pageName="Produtos – Easy Control"
                    breadcrumbsImg={bannerbg}
                />
                {/* breadcrumb-area-start */}

                <EasyControlContent />
            </div>
            <Footer />
        </React.Fragment>

    );
}


export default AndonZurg;