import React from 'react';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SectionTitle from '../../components/Common/SectionTitle';
import ContactForm from '../../components/Contact/ContactForm';
import ContactInfo from '../../components/Contact/ContactInfo';
import ScrollToTop from '../../components/Common/ScrollTop';

import bannerbg from '../../assets/img/05-contact/banner.png';


const ContactContent = () => {

        return (
            <React.Fragment>

                {/* SiteBreadcrumb */}
                <SiteBreadcrumb
                    pageTitle="Contato"
                    pageName="Contato"
                    breadcrumbsImg={bannerbg}
                />
                {/* SiteBreadcrumb */}

                {/* Contact Section Start */}
                <div className="rs-contact pt-100 md-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 md-mb-60">
                                <ContactInfo />
                            </div>
                            <div className="col-lg-8 pl-70 md-pl-14">
                                <div className="contact-widget">
                                    <SectionTitle
                                        sectionClass="sec-title2 mb-40"
                                        subtitleClass="sub-text contact mb-14"
                                        subtitle="FALE CONOSCO"
                                        titleClass="title testi-title"
                                        title="Nos envie uma mensagem sem compromisso"
                                    />

                                    {/* Contact Form */}
                                    <ContactForm />
                                    {/* Contact Form */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
                {/* Contact Section End */}
            </React.Fragment>

        );
}


export default ContactContent;