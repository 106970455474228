import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../../components/Common/SectionTitle';
import SingleTestimonial from '../../../components/Testimonial/SingleTestimonial';


import quote from '../../../assets/img/08-testimonial/quote.png';
import imgThomaz from '../../../assets/img/08-testimonial/Thomaz.png';
import imgVilmar from '../../../assets/img/08-testimonial/Vilmar.png';
import imgIgor from '../../../assets/img/08-testimonial/Igor.png'
import imgGabriel from '../../../assets/img/08-testimonial/Gabriel.png'
import imgVitoria from '../../../assets/img/08-testimonial/Vitoria2.png'
import imgEvandro from '../../../assets/img/08-testimonial/Evandro.png'

function Testimonial() {

    const options = {
        items: 3,
        nav: false,
        dots: true,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        loop: true,
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                dots: false,
            },
            768: {
                items: 2,
                stagePadding: 0,
                dots: true,

            },
            992: {
                items: 2,
                stagePadding: 0,

            },
            1200: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

	return(
        <React.Fragment>
            <div className="rs-testimonial main-home gray-bg pt-100 pb-100 md-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text"
                        subtitle="Depoimentos"
                        titleClass="title testi-title"
                        title="Feedback dos nossos clientes"
                        effectClass="heading-line"
                    />
                    <OwlCarousel options={options} >
                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={imgVilmar}
                            Title="Vilmar S Barbosa" 
                            Designation="SINOVA" 
                            Description={
                                <p style={{fontSize:'15px', fontWeight:'lighter', textAlign:'justify'}}>
                                    Trabalho na Sinova que iniciou uma parceria com a ZURG há quase 15 anos, 
                                    acompanho esta parceria desde o início. 
                                    Posso dizer quanto a parceria, resiliência e muito profissionalismo que a 
                                    ZURG empregou nos mais diversos projetos que trabalhamos juntos. 
                                    Que tenhamos muito mais projetos juntos!
                                </p>}
                        />

                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={imgThomaz}
                            Title="Thomaz Pacheco" 
                            Designation="OMA Galeria" 
                            Description={
                                <p style={{fontSize:'15px', fontWeight:'lighter', textAlign:'justify'}}>
                                    Atenderam 100% a expectativa,
                                    com prazo legal e dentro do
                                    nosso orçamento.
                                    Sempre curiosos e atenciosos
                                    com o nosso negócio, propondo
                                    soluções boas e práticas.
                                </p>}
                        />

                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={imgGabriel}
                            Title="Gabriel Meduri" 
                            Designation="Easy5" 
                            Description={
                                <p style={{fontSize:'15px', fontWeight:'lighter', textAlign:'justify'}}>
                                    Trabalhar com a Zurg foi mais fácil do que eu imaginava, conseguiram materializar 
                                    exatamente como imaginamos a nossa solução e com melhorias. 
                                    O fato de dedicarem atenção mais do que especial à fase de concepção do projeto 
                                    é um diferencial importante, mergulharam com a gente nessa jornada e o que recebemos 
                                    foi resultado de muita competência e profissionalismo.
                                    Nós sonhamos e a Zurg realizou!
                                </p>}
                        />

                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={imgEvandro}
                            Title="Evandro Fereira" 
                            Designation="SINOVA" 
                            Description={
                                <p style={{fontSize:'15px', fontWeight:'lighter', textAlign:'justify'}}>
                                    Trabalho com a Zurg a 8 anos, e a SINOVA a 12 anos, e é uma parceira importantíssima 
                                    para o nosso negócio de Veículos Autônomos. A Zurg está sempre atenta à evolução 
                                    tecnológica, fortalecendo o Core e o Case do negócio.
                                </p>}
                        />

                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={imgIgor}
                            Title="Igor Iansen" 
                            Designation="Atemi Soluções Industriais" 
                            Description={
                                <p style={{fontSize:'15px', fontWeight:'lighter', textAlign:'justify'}}>
                                    Há anos trabalhamos em parceria com a Zurg,
                                    onde eles sempre desenvolvem soluções especiais com aplicações de alto nível.
                                    Nosso maior case até o momento foi na Ypê onde foi desenvolvido uma solução 
                                    (Andon) para monitoramento de 48 docas do Centro de distribuição da planta de Amparo.
                                </p>}
                        />
                        
                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={imgVitoria}
                            Title="Vitória Pecora" 
                            Designation="OMA Galeria" 
                            Description={
                                <p style={{fontSize:'15px', fontWeight:'lighter', textAlign:'justify'}}>
                                    Equipe muito solícita, sempre pronta a atender suas necessidades e anseios.
                                    Com um excelente atendimento
                                    pós-venda, nos auxiliando
                                    sempre que precisamos.
                                </p>}
                        />

                        
                        
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
	)
}

export default Testimonial