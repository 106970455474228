import React from 'react';
import { Link } from 'react-router-dom';

import footerLogo1 from '../../../assets/img/10-logo/logo-zurg.png';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4">
                            <div className="footer-logo">
                                <Link to="/" ><img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" /></Link>
                            </div>
                            <div className="textwidget pb-30">
                                <p style={{fontSize:'14px', lineHeight:'130%'}}>
                                    Contamos com um time multidisciplinar de programadores e designers prontos para tornar seu projeto de tecnologia realidade, seja ele um aplicativo, sistema ou website.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30"></div>
                        <div className="col-lg-3 col-md-12 col-sm-12 md-mb-30">
                            <h3 className="widget-title">Contato</h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc">contato@zurg.com.br</div>
                                </li>
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">
                                        Rua Jurubatuba, 1350<br/>
                                        Conjunto 913 - Box 039<br/>
                                        São Bernardo do Campo - SP<br/>
                                        09725-210
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <h3 className="widget-title" style={{marginBottom:'15px'}}>Redes Sociais</h3>
                                <ul className="footer-social">
                                    <li style={{marginLeft:'10px'}}>
                                        <a href="https://linkedin.com/company/zurgsistemas" target="_blank">
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                    </li>
                                    <li style={{marginLeft:'30px'}}></li>
                                    <li>
                                        <a href="https://pt-br.facebook.com/zurg.sistemas" target="_blank">
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                </ul>
                                
                                {/* newsletter-area-start */}
                                {/* <Newsletter /> */}
                                {/* newsletter-area-end */}
                        </div>

                        <div className="copyright md-text-center mb-20">
                            <p>© 2021 All Rights Reserved - Zurg Sistemas</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <FooterBottom /> */}
        </footer>
    );
}

export default Footer;