import React, { useState } from 'react';
import ButtonMailto from '../Mailto/ButtonMailto';

const ContactForm = (props) => {
    const { submitBtnClass } = props;    
    
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [organization, setOrganization] = useState("");
    const [message, setMessage] = useState("");

    return (
        <form id="contact-form" action="#">
            <div className="row">
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="name" name="name" onChange={(e) => setName(e.target.value)} placeholder="Nome" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="email" name="email" onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="phone" name="phone" onChange={(e) => setPhone(e.target.value)} placeholder="Telefone" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="organization" name="organization" onChange={(e) => setOrganization(e.target.value)} placeholder="Organização" />
                </div>

                <div className="col-12 mb-30">
                    <textarea className="from-control" id="message" name="message" onChange={(e) => setMessage(e.target.value)} placeholder="Mensagem" required></textarea>
                </div>
            </div>
            <div className="btn-part">
                <ButtonMailto children="Enviar" 
                              zurgEmail="contato@zurg.com.br"
                              subject="Contato - Website Zurg"
                              name={name}
                              email={email}
                              phone={phone}
                              organization={organization}
                              message={message}/>
            </div>
        </form>
    );

}

export default ContactForm;