import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import HomeContent from './HomeContent';

const Home = () => {
	return (
		<React.Fragment>
			<Header
				parentMenu='home'
				secondParentMenu='multipage'
				activeMenu='/home'
			/>
			<HomeContent />
			<Footer footerClass="rs-footer" />
		</React.Fragment>
	);
}

export default Home;
