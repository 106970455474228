import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import ScrollToTop from '../../components/Common/ScrollTop';
import VideoStyleThree from '../../components/Video/VideoStyleThree';

// Service Image
import bubble from '../../assets/img/03-service/bubble.png';
import bubbleBgEffectImg from '../../assets/img/03-service/bubble-bg.png';
import dotBgEffectImg from '../../assets/img/03-service/dot-bg.png';

// Areas
import chooseImg from '../../assets/img/03-service/choose.png';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';


const ServiceContent = () => {
    return (
        <React.Fragment>
            {/* About Section Start */}
            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text primary-color"
                                subtitle="Nossos Serviços"
                                titleClass="title"
                                title="Deixe a Zurg conectar-se com a sua empresa"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description=""
                                secondDescClass="desc"
                                secondDescription={
                                    <p>
                                        Nosso compromisso é garantir que seu projeto conte com as <br/> melhores tecnologias para que você possa se preocupar em gerar <br/> negócios para sua empresa. 
                                    <br/>
                                    <br/>
                                        Do pequeno empreendedor até o gerente de uma grande empresa, <br/> nosso time está preparado para entregar soluções que geram valor <br/> no longo prazo aos nossos parceiros.
                                    </p>}
                            />
                            {/* Section Title End */}
                            <div className="btn-part mt-46">
                                <Link to="/contact">
                                    <a className="readon learn-more">Fale Conosco</a>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="rs-animation-shape">
                                <div className="images">
                                    <img src={bubble} alt="" />
                                </div>
                                <div className="middle-image2">
                                    <img className="dance" src={bubbleBgEffectImg} alt="" />
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shape-image">
                    <img className="bottom dance" src={dotBgEffectImg} alt="" />
                </div>
            </div>
            {/* About Section End */}

            {/* Why Choose US Section Start */}
            <div className="rs-faq gray-bg pt-120 pb-120 md-pt-80 md-pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pr-46 md-pr-15 md-mb-50">
                            <div className="choose-img">
                                <img className="main" src={chooseImg} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-wrap">
                                <SectionTitle
                                    sectionClass="sec-title mb-44"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="Atuações"
                                    titleClass="title title6"
                                    title="Nós oferecemos diversos tipos de serviços"
                                />
                                <Accordion className="accodion-style1" allowZeroExpanded={true}>
                                    <AccordionItem uuid="a">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Automação Industrial
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <ul>
                                                <li>• Apontamento de qualidade;</li>
                                                <li>• Analytics para monitoramento de paradas e predição de falhas;</li>
                                                <li>• Andon;</li>
                                                <li>• Controle de movimentação de materiais;</li>
                                                <li>• Instrução de montagem;</li>
                                                <li>• IoT, integrado com serviços de cloud computing (Azure, AWS), SAAS/PAAS ou on-premisses;</li>
                                                <li>• Poka-Yoke;</li>
                                                <li>• Rastreabilidade;</li>
                                                <li>• Sequenciamento de produção e balanceamento de carga;</li>
                                                <li>• Sistemas para coleta de dados.</li>
                                            </ul>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="b">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Aplicativos Móveis
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <ul>
                                                <li>• Aplicativos para coleta e apontamento de dados;</li>
                                                <li>• Aplicativos institucionais ou especiais;</li>
                                                <li>• Monolíticos ou com suporte server-side;</li>
                                                <li>• Suporte à processos de venda.</li>
                                            </ul>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="c">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Desenvolvimento de Sistemas
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <ul>
                                                <li>• Aplicações B2B ou B2C;</li>
                                                <li>• Drivers de comunicação;</li>
                                                <li>• Interface para integração entre sistemas;</li>
                                                <li>• Sistema de controle de acesso.</li>
                                            </ul>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="d">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Desenvolvimento Web
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <ul>
                                                <li>• Aplicações para publicidade;</li>
                                                <li>• Web sites estáticos ou dinâmicos.</li>
                                            </ul>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="e">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Projetos Customizáveis
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <ul>
                                                <li>Desenvolvimento e suporte de projetos personalizados, visando construir soluções para problemas complexos e assim, transformando suas ideias em realidades.</li>
                                            </ul>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Why Choose US Section End */}

            {/* Contact area start */}
            <div class="rs-contact-wrap bg-gradient pt-120 pb-390 md-pt-80">
                <div class="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Contato"
                        titleClass="title title2 white-color"
                        title="Vamos conversar?"
                    />
                </div>
            </div>
            <VideoStyleThree />
            {/* Contact area end */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}

export default ServiceContent;