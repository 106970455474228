import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../../components/Common/SectionTitle';

import imgMobileZurg from '../../../assets/img/01-home/mobile-zurg.png';
import imgLamp from '../../../assets/img/01-home/lamp.png';
import imgOffice from '../../../assets/img/01-home/office-mac.png';
import imgCheck from '../../../assets/img/01-home/check-orange.png';

const About = (props) => {
    return (
        <div id="rs-about" className="rs-about pt-100 pb-100 md-80 md-80 sm-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="img-wrap">
                            <img className="main" src={imgMobileZurg} alt="" />
                            <div className="ly1">
                                <img className="dance" src={imgLamp} alt="" />
                            </div>
                            <div className="ly2">
                                <img className="dance2" src={imgOffice} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 pl-40 md-pl-14 md-pt-240 sm-pt-66">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title mb-60"
                            subtitleClass="sub-text primary-color"
                            subtitle="SOBRE"
                            titleClass="title pb-20"
                            title={<p style={{lineHeight:'120%'}}>Nosso propósito é facilitar <br/> a vida de quem quer tirar <br/> uma ideia do papel</p>}
                            descClass="desc pr-90 md-pr-0"
                            description={<p>Em mais de 11 anos de história da Zurg Sistemas, seguimos apoiando e acompanhando nossos parceiros e amigos clientes, entendendo suas necessidades e restrições, para assim, solucionarmos seus problemas. <br/> Desenvolvemos sistemas que geram segurança, qualidade e escalabilidade para o seu negócio. </p>}
                        />
                        {/* Section Title End */}
                        <div className="row">
                            <div className="col-lg-10">
                                {/* Skill Bar Start */}
                                <div className="rs-skillbar style1">
                                    <div style={{display: 'flex'}}>
                                        <img src={imgCheck} alt="" style={{height:'15px', marginRight:'20px', marginTop:'3px'}}/>
                                        <h6>Automação Industrial</h6>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <img src={imgCheck} alt="" style={{height:'15px', marginRight:'20px', marginTop:'3px'}}/>
                                        <h6>Aplicativos Móveis</h6>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <img src={imgCheck} alt="" style={{height:'15px', marginRight:'20px', marginTop:'3px'}}/>
                                        <h6>Desenvolvimento de Sistemas</h6>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <img src={imgCheck} alt="" style={{height:'15px', marginRight:'20px', marginTop:'3px'}}/>
                                        <h6>Desenvolvimento Web</h6>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <img src={imgCheck} alt="" style={{height:'15px', marginRight:'20px', marginTop:'3px'}}/>
                                        <h6>Projetos Customizáveis</h6>
                                    </div>
                                </div>
                                {/* Skill Bar End */}
                                <div className="btn-part" style={{marginTop:'45px'}}>
                                    <Link to="/about" className="readon learn-more">Saiba Mais</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;