import React from 'react';

import SectionTitle from '../../../components/Common/SectionTitle';
import ScrollToTop from '../../../components/Common/ScrollTop';
import VideoStyleThree from '../../../components/Video/VideoStyleThree';

import contentImg1 from '../../../assets/img/04-project/andon-zurg/content-1.png';
import contentImg2 from '../../../assets/img/04-project/andon-zurg/content-2.png';

const AndonZurgContent = () => {
    return (
        <React.Fragment>
            {/* About Section Start */}
            <div className="rs-process gray-bg pt-100 pb-100 md-70">
                {/* <div className="container"> */}
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-50"
                        subtitleClass="sub-text primary-color"
                        subtitle="PRODUTOS"
                        titleClass="title"
                        title="Andon Zurg"
                        effectClass="heading-line"
                    />

                    <div style={{textAlign:'center', marginBottom:'50px'}}>
                        <p>
                            A dinâmica da indústria e de outras empresas dos tempos modernos é bastante complexa.
                        <br/>
                        <br/>
                            No contexto industrial, isso pode ter grande impacto: afinal, a parada de uma linha de produção pode gerar perdas imensuráveis.
                        </p>
                    </div>

                    <div style={{marginBottom:'50px'}}>
                        <img src={contentImg1} alt=""/>
                    </div>

                    <div style={{textAlign:'center', marginBottom:'50px'}}>
                        <p>
                            Com esse problema em vista, possuímos o sistema <b>Andon Zurg</b> - ferramenta que acompanha cada célula produtiva/máquina, sinaliza e registra paradas de linha.
                        </p>
                    </div>

                    <div style={{marginBottom:'50px'}}>
                        <img src={contentImg2} alt=""/>
                    </div>

                    <div style={{textAlign:'center'}}>
                        <p>
                            Com o sistema <b>Andon Zurg</b> fica fácil acompanhar suas metas, identificar desvios, tomar ações corretivas com maior celeridade e objetividade,
                            <br/>
                            além de poder acompanhar pela internet, através de seu PC ou dispositivo móvel.
                        </p>
                    </div>
                {/* </div> */}
            </div>
            {/* About Section End */}
            
            {/* Contact area start */}
            <div class="rs-contact-wrap bg-gradient pt-100 pb-390 md-pt-80">
                <div class="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Contato"
                        titleClass="title title2 white-color"
                        title="Vamos conversar?"
                    />
                </div>
            </div>
            <VideoStyleThree />
            {/* Contact area end */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}

export default AndonZurgContent;