import React  from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../../components/Common/SectionTitle';
import CounterDefault from '../../../components/Elements/Counter/CounterDefault';

const CallUs = () => {
    return (
        <div className="rs-call-us relative pt-100 md-pb-80 mb-50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 hidden-md">
                        {/* <div className="image-part">
                            <img src={contactHereImg} alt="" />
                        </div> */}
                    </div>
                    <div className="col-lg-6 pb-120 md-pb-80">
                        <div className="rs-contact-box text-center">
                            <div className="address-item mb-56">
                                <div className="address-icon">
                                    <i className="fa fa-envelope"></i>
                                </div>
                            </div>
                            <SectionTitle
                                sectionClass="sec-title3 text-center"
                                subtitleClass="sub-text"
                                subtitle=""
                                titleClass="title"
                                title=""
                                descClass="desc"
                                description={<p>Tem alguma ideia ou projeto em mente? <br/> Entre em contato!</p>}
                            />
                            <div className="btn-part mt-70">
                                <a className="readon lets-talk" href="mailto:contato@zurg.com.br">Fale Conosco</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* counter area start */}
            <CounterDefault />
            {/* counter area end */}
        </div>
    );
}

export default CallUs;