import React from "react";
import { Link } from "react-router-dom";

const ButtonMailto = ({ zurgEmail, subject = '', name = '', email = '', phone = '', organization = '', message = '', children }) => {
    let params = subject || name ? '?' : '';

    if (subject) params += `subject=${encodeURIComponent(subject)}`;

    let body = 'Informações do Contato \n\n'

    if(name) body += '• Nome: ' + name;
    if(email) body += '\n• E-mail: ' + email;
    if(phone) body += '\n• Telefone: ' + phone;
    if(organization) body += '\n• Organização: ' + organization;
    if(message) body += '\n\n• Mensagem: ' + message;

    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
    
    return <a className={'readon learn-more submit'} type='submit' href={`mailto:${zurgEmail}${params}`}>{children}</a>;
};

export default ButtonMailto;