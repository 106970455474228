import React from 'react';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';

//Custom Components
import Home from '../pages/home';
import About from '../pages/about';
import Services from '../pages/service';
import AndonZurg from '../pages/product/andon-zurg';
import EasyControl from '../pages/product/easy-control';
import Contact from '../pages/contact';
import Error from '../pages/error/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'
import GA4React, { useGA4React } from "ga-4-react";

const ga4react = new GA4React("G-K8ZDG0HMJC");

const App = () => {
    
    const ga = useGA4React();
    console.log(ga);
    ga4react.initialize();

    return (
        <div className='App'>
            <Router>
                <LoadTop />          
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/home" component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/service" exact component={Services} />
                    <Route path="/product-andon-zurg" exact component={AndonZurg} />
                    <Route path="/product-easy-control" exact component={EasyControl} />
                    <Route path="/contact" component={Contact} />
                    <Route component={Error} />
                </Switch>
            </Router>            
        </div>
    );
}

export default App;
