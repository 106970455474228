import { Link } from 'react-router-dom';

const ErrorContent = () => {
	return (
		<div className="page-error">
            <div style={{position:'fixed', left:'14%', top:'85%'}}>
                <Link to="/">
                    <a className="readon learn-more">Voltar</a>
                </Link>
            </div>
        </div>
	);
}

export default ErrorContent;