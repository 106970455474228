import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const RSMobileMenu = ({ setMenuOpen, menuOpen, activeMenu }) => {

	const [product, setProduct] = useState(false)

	const openMobileMenu = menu => {
		if (menu === 'product') {
			setProduct(true)
		}
		else
		{
			setProduct(false)
		}
	};

	return (
		<div className="container relative">
			<div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
				<div className="mobile-menu">
					<ul className="nav-menu">
						<li>
							<Link to="/home" className={activeMenu === "home" ? "active-menu" : ""}>Home</Link>
						</li>
						<li>
							<Link to="/about" className={activeMenu === "about" ? "active-menu" : ""}>Sobre</Link>
						</li>
						<li>
							<Link to="/service" className={activeMenu === "service" ? "active-menu" : ""}>Serviços</Link>
						</li>
						<li className={product || activeMenu === "product-andon-zurg" || activeMenu === "product-easy-control" ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
							<Link onClick={() => { openMobileMenu('product'); }}>Produtos</Link>
							<ul className={product || activeMenu === "product-andon-zurg" || activeMenu === "product-easy-control" ? "sub-menu current-menu" : "sub-menu"}>
								<li>
									<Link to="product-andon-zurg" className={activeMenu === "product-andon-zurg" ? "active-menu" : ""}>Andon Zurg</Link>
								</li>
								<li>
									<Link to="product-easy-control" className={activeMenu === "product-easy-control" ? "active-menu" : ""}>Easy Control</Link>
								</li>
							</ul>
						</li>
						<li>
							<Link to="/contact" className={activeMenu === "contact" ? "active-menu" : ""}>Contato</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default RSMobileMenu;