import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';
import SingleTeam from '../../components/Team/SingleTeam';
import VideoStyleThree from '../../components/Video/VideoStyleThree';


// Mission Value Vision Images
import missionIcon from '../../assets/img/02-about/icons/globe.png';
import valueIcon from '../../assets/img/02-about/icons/hand-heart.png';
import visionIcon from '../../assets/img/02-about/icons/eye.png';

// Team Images
import jefersonImg from '../../assets/img/06-team/Jeferson.png';
import muriloImg from '../../assets/img/06-team/Murilo.png';
import fabioImg from '../../assets/img/06-team/Fabio.png';
import lucianeImg from '../../assets/img/06-team/Luciane.png';
import leonardoImg from '../../assets/img/06-team/Leonardo.png';
import lucasImg from '../../assets/img/06-team/Lucas.png';
import keittyImg from '../../assets/img/06-team/Keitty.png';
import fabianoImg from '../../assets/img/06-team/Fabiano.png';
import marianaImg from '../../assets/img/06-team/Mariana.png';

import imgMSPartner from '../../assets/img/02-about/msPartner.png';
import imgNVidiaPartner from '../../assets/img/02-about/nvidia.png';



import bubble from '../../assets/img/02-about/bubble.png';
import bubbleBgEffectImg from '../../assets/img/02-about/bubble-bg.png';

const AboutContent = () => {

    const options = {
        items: 3,
        nav: false,
        dots: true,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        loop: true,
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                dots: false,
            },
            768: {
                items: 2,
                stagePadding: 0,
                dots: true,
            },
            992: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

    return (
        <React.Fragment>
            {/* about-area-start */}
            <div id="rs-about" className="rs-about white-bg pt-100 pb-100 md-80 md-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 md-mb-30">
                            <div className="rs-animation-shape">
                                <div className="images">
                                    <img src={bubble} alt="" />
                                </div>
                                <div className="middle-image2">
                                    <img className="dance" src={bubbleBgEffectImg} alt="" />
                                </div> 
                            </div>
                        </div>
                        <div className="col-lg-6 pl-60 md-pl-14">
                            <div className="contact-wrap">
                                {/* Section Title Start */}
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text style-bg mb-100"
                                    subtitle="Sobre Nós"
                                    titleClass="title"
                                    title="Nosso principal objetivo é facilitar a sua rotina"
                                    descClass="desc pb-34"
                                    description=""
                                    secondDescClass="margin-0 pb-16"
                                    secondDescription={
                                    <p>
                                        Nossa história começa em meados de 2011, quando um grupo de desenvolvedores decidiu criar soluções 
                                        que deixassem o dia a dia do empreendedor mais simples.
                                    <hr style={{visibility:'hidden', margin:'8px 0'}}/>
                                        Buscando a melhor solução, a ZURG desenvolve sistemas que geram segurança, qualidade e escalabilidade para o seu negócio. 
                                    <hr style={{visibility:'hidden', margin:'8px 0'}}/>
                                        Nossos produtos e serviços são personalizados, pensados e desenvolvidos conforme a sua necessidade.
                                    <hr style={{visibility:'hidden', margin:'8px 0'}}/>
                                        Somos apaixonados por tecnologia  e contamos com um time de profissionais preocupados em levar sempre o melhor a sua empresa. 
                                    <hr style={{visibility:'hidden', margin:'8px 0'}}/>    
                                        Nosso compromisso é garantir que você possa contar com o que há de mais moderno no mercado, podendo assim se preocupar apenas com o que realmente importa: gerar négocios!
                                    </p>
                                    }
                                />
                                {/* Section Title End */}
                                <div className="btn-part">
                                    <Link className="readon learn-more" to="/contact">Fale Conosco</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="shape-image">
                        <img className="top dance" src={effectImg1} alt="" />
                        <img className="bottom dance" src={effectImg2} alt="" />
                    </div> */}
                </div>
            </div>
            {/* about-area-end */}

            {/* working-process-area-start */}
            <div className="rs-services style4 modify1 gray-bg pt-100 pb-100 md-70">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Somos a Zurg"
                        titleClass="title title2"
                        title="Indo além dos resultados esperados"
                        effectClass="heading-line"
                    />
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-26">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={missionIcon}
                                Title="Missão" 
                                Text="Nascemos para criar soluções em tecnologia, trazendo praticidade e competitividade a nossos clientes e parceiros." 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-26">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={valueIcon}
                                Title="Valores" 
                                Text="Somos apaixonados pelo que fazemos. 
                                    A satisfação de nossos clientes é a nossa maior motivação.
                                    Valorizamos a transparência, a verdade e o respeito, acima de tudo.	
                                    O aprendizado e capacitação da nossa equipe é continuo, para entregamos sempre o melhor. 
                                    Corremos juntos ao encontro do sucesso." 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-26">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={visionIcon}
                                Title="Visão" 
                                Text="Ser reconhecida no mercado de desenvolvimento como uma empresa que entende seus clientes e que, acima de tudo, busca sempre oferecer a melhor solução. 
                                Estamos preparados para promover inovação aos nossos clientes. " 
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}
            
            {/* Team Area Start */}            
            <div className="rs-team modify1 pt-100 pb-100 md-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Nosso Time"
                        titleClass="title title2"
                        title="Nossa equipe é composta por pessoas engajadas, focadas e que amam o que fazem!"
                        effectClass="heading-line"
                    />
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-50">
                            <SingleTeam
                                itemClass="team-item-wrap"
                                teamImage={jefersonImg}
                                Title="Jeferson"
                                Designation="Dev"
                                LinkedIn="https://www.linkedin.com/in/jeferson-flamini/"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-50">
                            <SingleTeam
                                itemClass="team-item-wrap"
                                teamImage={lucianeImg}
                                Title="Luciane"
                                Designation="Admin/RH"
                                LinkedIn="https://www.linkedin.com/in/luciane-c-flamini-6559b933/"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-50">
                            <SingleTeam
                                itemClass="team-item-wrap"
                                teamImage={fabianoImg}
                                Title="Fabiano"
                                Designation="Dev"
                                LinkedIn="https://www.linkedin.com/in/fabiano-andrade-13118475/"
                            />
                        </div>
                       
                    </div>
                </div>
            </div>
            {/* Team Area End */}


            {/* Partnership area start */}
            <div class="rs-services style4 modify1 gray-bg pt-100 pb-100 md-70">
                <div class="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Parcerias / programas de incentivo"
                        titleClass="title title2 white-color"
                        title=""
                    />
                    <div class='row'>
                        
                        <div className="col-lg-4 col-md-6 mb-50"  style={{display: 'grid', textAlign:'center'}}>
                            <img src={imgMSPartner}></img>                            
                            Fornecemos produtos, serviços e suporte relacionados aos produtos Microsoft/Azure.
                        </div>
                        <div className="col-lg-4 col-md-6 mb-50">
                            
                        </div>
                        <div className="col-lg-4 col-md-6 mb-50">
                            <img src={imgNVidiaPartner}></img>
                        </div>
                    
                    </div>
                </div>
            </div>            
            {/* Partnership area end */}


            {/* Contact area start */}
            <div class="rs-contact-wrap bg-gradient pt-100 pb-390 md-80">
                <div class="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Contato"
                        titleClass="title title2 white-color"
                        title="Vamos conversar?"
                    />
                </div>
            </div>
            <VideoStyleThree />
            {/* Contact area end */}

        </React.Fragment>
    )
}

export default AboutContent;