import React from 'react';
import OwlCarousel from 'react-owl-carousel2';


import brandNormalLogo1 from '../../../assets/img/07-partner/brand-sinova.png';
import brandNormalLogo2 from '../../../assets/img/07-partner/brand-accede.png';
import brandNormalLogo3 from '../../../assets/img/07-partner/brand-grammer.png';
import brandNormalLogo4 from '../../../assets/img/07-partner/brand-easy.png';
import brandNormalLogo5 from '../../../assets/img/07-partner/brand-artis.png';
import brandNormalLogo6 from '../../../assets/img/07-partner/brand-staefa.png';
import brandNormalLogo7 from '../../../assets/img/07-partner/brand-oma.png';
import brandNormalLogo8 from '../../../assets/img/07-partner/brand-atemi.png';
import brandNormalLogo9 from '../../../assets/img/07-partner/brand-ype.png';
import brandNormalLogo10 from '../../../assets/img/07-partner/brand-ladoB.png';

import brandHoverLogo1 from '../../../assets/img/07-partner/brand-sinova.png';
import brandHoverLogo2 from '../../../assets/img/07-partner/brand-accede.png';
import brandHoverLogo3 from '../../../assets/img/07-partner/brand-grammer.png';
import brandHoverLogo4 from '../../../assets/img/07-partner/brand-easy.png';
import brandHoverLogo5 from '../../../assets/img/07-partner/brand-artis.png';
import brandHoverLogo6 from '../../../assets/img/07-partner/brand-staefa.png';
import brandHoverLogo7 from '../../../assets/img/07-partner/brand-oma.png';
import brandHoverLogo8 from '../../../assets/img/07-partner/brand-atemi.png';
import brandHoverLogo9 from '../../../assets/img/07-partner/brand-ype.png';
import brandHoverLogo10 from '../../../assets/img/07-partner/brand-ladoB.png';

const Brand = (props) =>  {
    const { brandBg, brandClass } = props;

    const brandBgStyle = {
        backgroundImage:`url(${brandBg ? brandBg : ''})`
    }

    const brandBgDefaultStyle = {
        backgroundColor: '#00005B'
    }

    const options = {
        items: 5,
        nav: false,
        dots: false,
        rewind: false,
        autoplay: true,
        stagePadding: 30,
        margin: 30,
        loop: true,
        center: false,
        responsive: {
            0: {
                items: 1,
            },
            480: {
                items: 2,
                stagePadding: 0,
            },
            768: {
                items: 3,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            },
            1500: {
                items: 5,
            }
        }
    };

    return (
        <div className={ brandClass ? brandClass : 'rs-partner pt-80 pb-70'}  style={brandBg ? brandBgStyle : brandBgDefaultStyle}>
            <div className="container">
                <OwlCarousel options={options}>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a>
                                <img className="hover-logo" src={brandHoverLogo1} alt="" />
                                <img className="main-logo" src={brandNormalLogo1}  alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a>
                                <img className="hover-logo" src={brandHoverLogo2} alt="" />
                                <img className="main-logo" src={brandNormalLogo2}  alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a>
                                <img className="hover-logo" src={brandHoverLogo3} alt="" />
                                <img className="main-logo" src={brandNormalLogo3}  alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a>
                                <img className="hover-logo" src={brandHoverLogo4} alt="" />
                                <img className="main-logo" src={brandNormalLogo4}  alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a>
                                <img className="hover-logo" src={brandHoverLogo5} alt="" />
                                <img className="main-logo" src={brandNormalLogo5}  alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a>
                                <img className="hover-logo" src={brandHoverLogo6} alt="" />
                                <img className="main-logo" src={brandNormalLogo6}  alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a>
                                <img className="hover-logo" src={brandHoverLogo7} alt="" />
                                <img className="main-logo" src={brandNormalLogo7}  alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a>
                                <img className="hover-logo" src={brandHoverLogo8} alt="" />
                                <img className="main-logo" src={brandNormalLogo8}  alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a>
                                <img className="hover-logo" src={brandHoverLogo9} alt="" />
                                <img className="main-logo" src={brandNormalLogo9}  alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a>
                                <img className="hover-logo" src={brandHoverLogo10} alt="" />
                                <img className="main-logo" src={brandNormalLogo10}  alt="" />
                            </a>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
        </div>
    );
}

export default Brand;