import React from 'react';
import { Link } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu, secondParentMenu, activeMenu } = props;

    return (
        <React.Fragment>
            <li className={parentMenu === 'home' ? 'current-menu-item' : ''}>
                <Link to="" as="/">Home</Link>
            </li>
            <li className={parentMenu === 'about' ? 'current-menu-item' : ''}>
                <Link to="/about" className={parentMenu === "/about" ? "active-menu" : ""}>Sobre</Link>
            </li>
            <li className={parentMenu === 'service' ? 'current-menu-item' : ''}>
                <Link to="/service" as="#">Serviços</Link>
            </li>
            <li className={parentMenu === 'product-andon-zurg' || parentMenu === 'product-easy-control' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <a>Produtos</a>
                <ul className="sub-menu">
                    <li>
                        <Link to="product-andon-zurg" className={parentMenu === "product-andon-zurg" ? "active-menu" : ""}>Andon Zurg</Link>
                    </li>
                    <li>
                        <Link to="product-easy-control" className={parentMenu === "product-easy-control" ? "active-menu" : ""}>Easy Control</Link>
                    </li>
                </ul>
            </li>
            <li className={parentMenu === 'contact' ? 'current-menu-item' : ''}>
                <Link to="/contact" className={parentMenu === "/contact" ? "active-menu" : ""}>Contato</Link>
            </li>
        </React.Fragment>
    );
}

export default MenuItems;